// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setCookie } from "cookies-next";
import { HYDRATE } from "next-redux-wrapper";
import { loginApi, facebookLoginApi, signupApi } from "../app/api/auth";
import { forgotPasswordApi } from "../app/api/forgotPasswordApi";
import {
  AUTH_FACEBOOK_LOGIN,
  AUTH_FORGOT_PASSWORD,
  AUTH_LOGIN,
  AUTH_SIGNUP,
  SUCCESS_MESSAGE,
} from "./types";
import { fetchUserData } from "./userSlice";
import { getUserSurvey, resetSurvey } from "./surveySlice";
import { toast } from "react-toastify";
import { ACCESS_TOKEN } from "../public/Constants/EnumConstants";

// Async thunk for logging in
export const login = createAsyncThunk(
  AUTH_LOGIN,
  async (credentials, { rejectWithValue, dispatch }) => {
    const data = await loginApi(credentials);
    if (data?.token) {
      const access_token = `JWT ${data?.token}`;
      setCookie(ACCESS_TOKEN, access_token);
      dispatch(fetchUserData());
      dispatch(getUserSurvey());
      return access_token;
    }
    return rejectWithValue(data?.detail);
  }
);
export const signup = createAsyncThunk(
  AUTH_SIGNUP,
  async (payload, { rejectWithValue, dispatch }) => {
    const response = await signupApi(payload);
    if (response?.status === 200 && response?.data?.token) {
      const access_token = `JWT ${response?.data?.token}`;
      setCookie(ACCESS_TOKEN, access_token);
      toast("An email has been sent to you with a link to verify your email.", {
        type: "success",
      });
      dispatch(resetSurvey());
      dispatch(fetchUserData());
      return access_token;
    } else if (response?.status === 400 && response?.error?.email) {
      if (toast) {
        toast(response?.error?.email[0], {
          type: "error",
        });
        return rejectWithValue(
          response?.error?.email[0] || "There are errors in your form"
        );
      }
    }
  }
);
export const facebookLogin = createAsyncThunk(
  AUTH_FACEBOOK_LOGIN,
  async (formData, { rejectWithValue, dispatch }) => {
    const data = await facebookLoginApi(formData);
    if (data?.access_token) {
      const access_token = `Bearer ${data?.access_token}`;
      dispatch(fetchUserData(access_token));
      setCookie(ACCESS_TOKEN, access_token);
      return access_token;
    }
    return rejectWithValue(data);
  }
);
export const forgotPassword = createAsyncThunk(
  AUTH_FORGOT_PASSWORD,
  async (email, { rejectWithValue }) => {
    const response = await forgotPasswordApi(email);
    if (response?.message === SUCCESS_MESSAGE) {
      return response?.message;
    }
    return rejectWithValue(response?.message);
  }
);

// Slice for authentication and user data
const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: null,
    isLoading: false,
    error: null,
    forgotPasswordSuccess: null,
  },
  reducers: {
    resetState: (state, action) => {
      const { payload } = action;
      switch (payload?.type) {
        case "error":
          state.error = null;
        case "forgotPasswordSuccess":
          state.forgotPasswordSuccess = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
          ...action?.payload?.auth,
        };
      })
      .addCase(login?.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login?.fulfilled, (state, action) => {
        state.accessToken = action?.payload?.accessToken;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(login?.rejected, (state, action) => {
        state.isLoading = false;

        state.error = action?.payload;
      })
      .addCase(signup?.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signup?.fulfilled, (state, action) => {
        state.accessToken = action?.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(signup?.rejected, (state, action) => {
        state.isLoading = false;

        state.error = action?.payload;
      })
      .addCase(facebookLogin?.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(facebookLogin?.fulfilled, (state, action) => {
        state.accessToken = action?.payload?.accessToken;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(facebookLogin?.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action?.payload;
      })
      .addCase(forgotPassword?.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(forgotPassword?.fulfilled, (state, action) => {
        state.isLoading = false;
        state.forgotPasswordSuccess = action?.payload;
        state.error = null;
      })
      .addCase(forgotPassword?.rejected, (state, action) => {
        state.isLoading = false;
        state.forgotPasswordSuccess = null;
        state.error = action?.payload;
      });
  },
});

export const { resetState } = authSlice?.actions;
export default authSlice?.reducer;
